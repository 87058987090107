const curFormatter = new Intl.NumberFormat('en-US', {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2
});

const curFormatterNoRem = new Intl.NumberFormat('en-US', {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0
});

export function fmtCur(amount, remainder = true) {
  if(remainder) {
    return curFormatter.format(amount);
  } 
  return curFormatterNoRem.format(amount);
}
